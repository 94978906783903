<script>
  import router from '@/router'
  import { get, call } from 'vuex-pathify'
  import CustomizeCard from '@/components/customize/CustomizeCard'
  import CustomizeVariantCard from '@/components/customize/CustomizeVariantCard'
  import screen from '@/helpers/screen'

  export default {
    components: {
      CustomizeCard,
      CustomizeVariantCard,
    },
    data() {
      return {
        selectedBoxId: null,
      }
    },
    computed: {
      screen,
      ...get('config', ['boxArchive']),
      filteredBoxArchive() {
        return this.boxArchive.filter(({ isCurrentBox }) => !isCurrentBox)
      },
      options() {
        return this.filteredBoxArchive.map(({ id, season, title, year }) => {
          return {
            label: `${title} ${year}`,
            value: id,
            season,
            title,
            year,
          }
        })
      },
      selectedOption() {
        return this.options.find((option) => option.value === this.selectedBoxId)
      },
      selectedBox() {
        return this.filteredBoxArchive.find(({ id }) => id === this.selectedBoxId)
      },
      seasonColor() {
        return this.selectedBox?.season?.toLowerCase() ?? 'dawn'
      },
    },
    async mounted() {
      await this.loadBoxArchive()
      this.selectedBoxId = this.filteredBoxArchive?.[0]?.id
    },
    methods: {
      ...call('config', ['loadBoxArchive']),

      navigateToDetails(variantId) {
        this.$navigate({
          name: 'BoxArchiveDetails',
          params: {
            variantId: variantId,
            boxId: this.selectedBox?.id,
          },
        })
      },
      goBack() {
        router.back()
      },
    },
  }
</script>

<template>
  <component
    :is="screen.md ? 'BaseContainer' : 'div'"
    max-width="md"
    class="bg-dawn md:pt-8 md:pb-14"
  >
    <BaseLinkStyled
      v-if="screen.md"
      color="black"
      font-size="xs"
      icon-position="left"
      :icon-size="3"
      @click="goBack"
    >
      <template v-slot:icon>
        <IconArrowLeft />
      </template>
      <template v-slot>Back</template>
    </BaseLinkStyled>
    <div v-if="$route.name === 'BoxArchiveDetails' && !screen.md" key="details">
      <RouterView />
    </div>
    <div class="flex flex-col md:mt-4">
      <!-- Header for mobile -->
      <div v-if="!screen.md" class="bg-dawn-lt3 flex justify-center py-4">
        <BaseIconClickable
          :size="5"
          label="back"
          color="black"
          class="absolute left-0 ml-4"
          @click="goBack"
        >
          <IconArrowLeft />
        </BaseIconClickable>
        <span class="font-semibold text-lg"> Past Boxes </span>
      </div>
      <!-- Hero image -->
      <BaseImage
        v-if="selectedBox"
        :src="`/static/boxArchive/Archive-${selectedBox.season}-${selectedBox.year}-${
          screen.md ? 'Desktop' : 'Mobile'
        }.jpg`"
        alt=""
        responsive="md"
      />
      <div>
        <!-- Need a wrapper div around the select tag so we can create a custom selector -->
        <div
          v-if="selectedOption"
          :class="`absolute z-20 w-full bg-${seasonColor} pointer-events-none`"
          tabindex="-1"
        >
          <div class="font-heading font-semibold text-xl text-center py-5">
            {{ selectedOption.label }}
            <BaseIcon
              :size="6"
              :class="`${
                screen.md ? 'inline-block align-middle ml-1' : 'absolute inset-0 m-auto mr-11'
              } `"
            >
              <IconChevronDown />
            </BaseIcon>
          </div>
        </div>
        <!-- for purgecss: bg-fall bg-summer bg-spring bg-winter -->
        <select
          v-model="selectedBoxId"
          aria-label="Season"
          class="w-full py-5 font-heading font-semibold text-xl cursor-pointer"
        >
          <option v-for="(option, optionIndex) in options" :key="optionIndex" :value="option.value">
            {{ option.label }}
          </option>
        </select>
      </div>
      <div v-if="selectedBox">
        <!-- Desktop list of variants -->
        <ul
          v-if="screen.md"
          class="mt-8 grid justify-center gap-x-3 gap-y-8 grid-cols-2 md:grid-cols-4"
        >
          <li
            v-for="variant in selectedBox.variants"
            :key="variant.id"
            class="w-34 2xs:w-42 xs:w-45 max-w-full"
          >
            <CustomizeVariantCard :variant="variant" @details="navigateToDetails(variant.id)" />
          </li>
        </ul>
        <!-- Mobile list of variants -->
        <ul v-else class="bg-dawn pb-10 md:px-20 pt-5">
          <li
            v-for="variant in selectedBox.variants"
            :key="variant.id"
            class="py-4 border-b border-dawn-dk2 mx-4"
          >
            <CustomizeCard
              :variant="variant"
              layout="horizontal"
              @details="navigateToDetails(variant.id)"
            />
          </li>
        </ul>
      </div>
    </div>
    <Portal to="modal">
      <RouterView />
    </Portal>
  </component>
</template>
